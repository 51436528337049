import http from "../../../service/axios/httpRequest";
import { logout } from "../../../service/localStorage/localStorage";

export const getAllPlansRequest = async () => {
     return await http.get("/bff/plan-bff/all-plans").then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}

export const EditPlansRequest = async (body) => {
     return await http.put("/bff/plan-bff", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          });
}

export const DeletePlansRequest = async (id) => {
     return await http.delete("/bff/plan-bff", { params: { planId: id } }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          });
}

export const CreatePlanRequest = async (body) => {
     return await http.post("/bff/plan-bff", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }

               alert(err.response.data.message)
               throw err;
          });
}
