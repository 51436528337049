import http from "../axios/httpRequest";
import { logout } from "../localStorage/localStorage";
import { useQuery } from "react-query";


export const GetUserHome = async () => {
    return await http.get("/bff/aux-bff/home").then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              throw err;
         });
}

export const useFetchUser = () => {
     return useQuery(["UseUser"], GetUserHome);
 };