import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { login } from "../../service/localStorage/localStorage";
import { useNavigate } from 'react-router-dom';


const Pass = () => {

    const { token } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        login(token)
        setTimeout(() => {
            navigate("/")
          }, 300);
    }, [navigate, token])


    return (
        <div>
            <h1>Redirected...</h1>
        </div>
    )
}

export default Pass