import { useState } from "react";
import { useMutation } from 'react-query';
import queryClient from "../../../service/reactQuery/queryClient";
import { useFetchPlansAll } from "../../Plans/Service/query";
import { useFetchUserAll } from "../Services/query";
import { EditUserRequest, SignUpRequest } from "../Services/request";


const UserState = () => {

    const { data: users, isLoading } = useFetchUserAll()
    const { data: plans } = useFetchPlansAll()
    const [userDialog, setUserDialog] = useState(false);
    const [createDialog, setCreateDialog] = useState(false);



    const EditUserMutation = useMutation(
        ({ body, close}) => EditUserRequest(body),
        {
            onMutate: () => {
            },
            onError: (error) => {

            },
            onSuccess: (result, veriables, context) => {
                queryClient.refetchQueries("UseUserAll")
                veriables.close()
            },
        }
    );

    const SignUpMutation = useMutation(({ body, close }) => SignUpRequest(body), {
        onMutate: () => {
        },
        onError: (error) => {
            console.log(error)
        },
        onSuccess: (result, variables, context) => {
            queryClient.refetchQueries("UseUserAll")
            variables.close()
        },
    });

    const CreateUser = (body, close) => {
        SignUpMutation.mutate({ body: body, close: close })
    }

    const EditUser = (body, close) => {
        EditUserMutation.mutate({ body: body, close: close})
    }

    return {
        users, isLoading, EditUser, CreateUser, plans, userDialog, setUserDialog, createDialog, setCreateDialog
    }
}

export default UserState