import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import * as Yup from 'yup';
import UserState from "../Context/state";


const ModalEditUser = ({ userDialog, user, hideDialog }) => {
    const { EditUser, plans } = UserState()

    const role = [
        "ADMIN",
        "USER",
        "BUSINESS"
    ]

    const UserSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Muito Curto!')
            .max(50, 'Muito Longo!')
            .required('Campo Obrigatório'),
        username: Yup.string()
            .min(2, 'Muito Curto!')
            .max(50, 'Muito Longo!')
            .required('Campo Obrigatório'),
        email: Yup.string().email('Invalid email').required('Campo Obrigatório'),

    });
   
    return (
        <Dialog visible={userDialog} style={{ width: '450px' }} header="Detalhes do usuário" modal className="p-fluid" onHide={hideDialog}>

            {user?.aws.url && <img src={`${user?.aws.url}`} alt={user?.name} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
            <Formik validationSchema={UserSchema} initialValues={{ plan: plans?.find(props => props?.id === user?.plan?.id), userId: user?.id, name: user?.name, email: user?.email, username: user?.username, role: role?.find(props => props === user?.role) }} onSubmit={(values) => {
                EditUser({...values, plan: values?.plan?.id}, hideDialog);
            }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {

                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText name="name" id="name" value={values?.name} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.name && touched.name })} />
                                {errors.name && touched.name && <small className="p-invalid">{errors.name}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Nome de usuário</label>
                                <InputText name="username" id="name" value={values?.username} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.username && touched.username })} />
                                {errors.username && touched.username && <small className="p-invalid">{errors.username}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">E-mail</label>
                                <InputText name="email" id="name" value={values?.email} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.email && touched.email })} />
                                {errors.email && touched.email && <small className="p-invalid">{errors.email}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Role</label>
                                <Dropdown options={role} name="role" id="name" value={values?.role} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.role && touched.role })} />
                                {errors.role && touched.role && <small className="p-invalid">{errors.role}</small>}
                            </div>

                            <div className="field">
                                <label htmlFor="name">Plano</label>
                                <Dropdown value={values?.plan} defaultValue={values?.plan} name="plan"  onChange={handleChange} options={plans} optionLabel="name"
                                    placeholder="Selecione o plano" className={classNames({ 'p-invalid': errors.plan && touched.plan })} />
                                {errors.plan && touched.plan && <small className="p-invalid">{errors.plan}</small>}

                            </div>
                            <div className="p-5"></div>
                            <div className="flex align-items-center justify-content-end">
                                <div className=" w-6 flex align-items-center justify-content-end">
                                    <Button label="Cancel" type="button" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    <Button label="Save" type="submit" icon="pi pi-check" className="p-button-text" />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>


        </Dialog>
    )
}

export default ModalEditUser