import http from "../../../service/axios/httpRequest";
import { logout } from "../../../service/localStorage/localStorage";


export const getChartsRequest = async () => {
     return await http.get("/bff/charts-bff").then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}