import { Chart } from 'primereact/chart';
import React from 'react';
import { StateDashboard } from './Context/state';

// const chart1 = {
//     labels: ['8Sun', '9Mon', '10Thu', '11Wed', '12Fri', '13Sat', '14Sun'],
//     datasets: [
//         {
//             label: 'Revenue',
//             data: [12, 19, 3, 5, 2, 3, 9],
//             borderColor: ['#FFA928'],
//             borderWidth: 4,
//             fill: true,
//             backgroundColor: ['rgba(255, 169, 40, .2)'],
//             tension: 0.4
//         }
//     ]
// };

// const chartOptions1 = {
//     plugins: {
//         legend: {
//             display: false
//         }
//     },
//     maintainAspectRatio: false,
//     hover: {
//         mode: 'index'
//     },
//     scales: {
//         x: {
//             display: false
//         },
//         y: {
//             display: false
//         }
//     }
// };

const chartOptions2 = {
    plugins: {
        legend: {
            display: false
        }
    },
    maintainAspectRatio: false,
    hover: {
        mode: 'index'
    },
    scales: {
        x: {
            display: true,
            grid: {
                color: 'transparent'
            },
            ticks: {
                color: '#BFC2C6'
            }
        },
        y: {
            display: true,
            grid: {
                color: 'rgba(191, 194, 198, .45)',
                borderDash: [5, 10]
            },
            ticks: {
                color: '#BFC2C6',
                min: 0,
                stepSize: 5
            }
        }
    }
};



const Dashboard = () => {

    const { charts } = StateDashboard()

    const getDayMonth = (chart) => {
        const array = [];
        chart.forEach(element => {
            array.push(`${element?.day}/${element?.month}`)
        });

        return array
    }
    const getResultTotal = (chart) => {
        let countTotal = 0;
        chart.forEach(element => {
            countTotal = element?.count + countTotal;
        });

        return countTotal
    }

    const getResultCHart = (chart) => {
        const array = [];
        chart.forEach(element => {
            array.push(`${element?.count}`)
        });

        return array
    }

    const getChart = (chart, label) => {
        const borderColor = getComputedStyle(document.body).getPropertyValue('--primary-color') || '#2c84d8';
        const backgroundColor = getComputedStyle(document.body).getPropertyValue('--primary-lighter-color') || '#2c84d8';
        return {
            labels: getDayMonth(chart),
            datasets: [
                {
                    label: label,
                    data: getResultCHart(chart),
                    borderColor: [borderColor],
                    borderWidth: 4,
                    fill: true,
                    backgroundColor: [backgroundColor],
                    tension: 0.4
                }
            ]
        };
    };

    return (
        <>
            {charts ? <div className="layout-dashboard">
                <div className="grid">
                    {/* <div className="col-12">
                        <div className="notification">
                            <h6>
                                👋 Hello! Welcome to Hit! Before start please complete your profile to know you better.{' '}
                                <button className="p-link">
                                    Profile settings <i className="pi pi-arrow-up"></i>
                                </button>
                            </h6>
                        </div>
                    </div> */}

                    <div className="col-12 pb-0">
                        <div className="grid">
                            <div className="col">
                                <div className="card overview-box blue">
                                    <div className="overview-info">
                                        <h6>Total de usuários</h6>
                                        <h1>{charts?.usersCharts?.total}</h1>
                                    </div>
                                    <i className="pi pi-users"></i>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overview-box white">
                                    <div className="overview-info">
                                        <h6>Total de Zonas</h6>
                                        <h1>{charts?.zonesCharts?.total}</h1>
                                    </div>
                                    <i className="pi pi-th-large"></i>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card overview-box gray">
                                    <div className="overview-info">
                                        <h6>Total de subzonas</h6>
                                        <h1>{charts?.subZonesCharts?.total}</h1>
                                    </div>
                                    <i className="pi pi-sitemap"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 pb-0">
                        <div className="grid">

                            <div className="col">
                                <div className="card overview-box darkgray">
                                    <div className="overview-info">
                                        <h6>Total de canais</h6>
                                        <h1>{charts?.channelsCharts?.total}</h1>
                                    </div>
                                    <i className="pi pi-video"></i>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overview-box orange">
                                    <div className="overview-info">
                                        <h6>Media de Usuários por Zona</h6>
                                        <h1>{charts?.zonesCharts?.averageZonesByZone}</h1>
                                    </div>
                                    <i className="pi pi-sliders-v"></i>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overview-box blue">
                                    <div className="overview-info">
                                        <h6>Cards criados nessa semana</h6>
                                        <h1>{charts?.subZonesCharts?.kanbanCardsCreatedLastWeek}</h1>
                                    </div>
                                    <i className="pi pi-credit-card"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 md:col-6 xl:col-3">
                        <div className="card timeline">
                            <div className="card-header">
                                <div className="card-title">
                                    <h6>Timeline</h6>
                                    <p className="subtitle">All servers</p>
                                </div>
                            </div>
                            <ul>
                                <li className="blue">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">New Sale</span>
                                        <span>Richard Jones has purchased a blue t-shirt for $79.</span>
                                        <span className="time">3 mins ago</span>
                                    </div>
                                </li>
                                <li className="blue">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">Log</span>
                                        <span>AWS settings are updated by admin@freya.com</span>
                                        <span className="time">12 mins ago</span>
                                    </div>
                                </li>
                                <li className="green">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">Capacity</span>
                                        <span>Reached 80% CPU capacity in Ireland. Automatic capacity increase initiated.</span>
                                        <span className="time">1:30PM</span>
                                    </div>
                                </li>
                                <li className="orange">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">Capacity</span>
                                        <span>Reached 60% CPU capacity in Ireland.</span>
                                        <span className="time">9:40AM</span>
                                    </div>
                                </li>
                                <li className="blue">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">Billing</span>
                                        <span>Upgraded plan, 10users yearly to 20users yearly</span>
                                        <span className="time">7:42AM</span>
                                    </div>
                                </li>
                                <li className="blue">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">New Sale</span>
                                        <span>Richard Jones has purchased a blue t-shirt for $79.</span>
                                        <span className="time">3 mins ago</span>
                                    </div>
                                </li>
                            </ul>
                            <button className="p-link">See all</button>
                        </div>
                    </div> */}

                    <div className="col-12 md:col-12 xl:col-6">
                        <div className="grid">
                            <div className="col-12">
                                <div className="card chart">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h6>Novas Zonas</h6>
                                            <p className="subtitle">Últimos 7 dias</p>
                                            <p className="subtitle">Total: {getResultTotal(charts?.zonesCharts?.createdLastWeekByDay)}</p>

                                        </div>
                                    </div>
                                    <Chart type="line" data={getChart(charts?.zonesCharts?.createdLastWeekByDay, "Novos Usuários")} options={chartOptions2} style={{ maxHeight: '330px' }}></Chart>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 md:col-12 xl:col-6">
                        <div className="grid">
                            <div className="col-12">
                                <div className="card chart">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h6>Novos Usuários</h6>
                                            <p className="subtitle">Últimos 7 dias</p>
                                            <p className="subtitle">Total: {getResultTotal(charts?.usersCharts?.createdLastWeekByDay)}</p>

                                        </div>
                                    </div>
                                    <Chart type="line" data={getChart(charts?.usersCharts?.createdLastWeekByDay, "Novos Usuários")} options={chartOptions2} style={{ maxHeight: '330px' }}></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 xl:col-6">
                        <div className="grid">
                            <div className="col-12">
                                <div className="card chart">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h6>Novas Subzonas</h6>
                                            <p className="subtitle">Últimos 7 dias</p>
                                            <p className="subtitle">Total: {getResultTotal(charts?.subZonesCharts?.createdLastWeekByDay)}</p>

                                        </div>
                                    </div>
                                    <Chart type="line" data={getChart(charts?.subZonesCharts?.createdLastWeekByDay, "Novos Usuários")} options={chartOptions2} style={{ maxHeight: '330px' }}></Chart>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 md:col-12 xl:col-6">
                        <div className="grid">
                            <div className="col-12">
                                <div className="card chart">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h6>Novos Canais</h6>
                                            <p className="subtitle">Últimos 7 dias</p>
                                            <p className="subtitle">Total: {getResultTotal(charts?.channelsCharts?.createdLastWeekByDay)}</p>

                                        </div>
                                    </div>
                                    <Chart type="line" data={getChart(charts?.channelsCharts?.createdLastWeekByDay, "Novos Usuários")} options={chartOptions2} style={{ maxHeight: '330px' }}></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </>
    );
};

export default Dashboard;
