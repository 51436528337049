import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils";
import UserState from "../Context/state";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { Password } from "primereact/password";


const ModalCreateUser = ({ createDialog, hideDialog }) => {
    const { CreateUser } = UserState()

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo Obrigatório'),
        username: Yup.string()
            .min(2, 'Muito curto!')
            .max(50, 'Muito Longo!')
            .required('Campo Obrigatório'),
        email: Yup.string().email('Invalid email').required('Campo Obrigatório'),
        password: Yup.string().required('Campo Obrigatório'),
        passwordConfirm: Yup.string().label('Confirmar senha').required("Campo Obrigatório").oneOf([Yup.ref('password')], 'Senhas diferentes'),
    });

    const initialValues = {
        name: "",
        username: "",
        email: "",
        password: "",
        passwordConfirm: ""
    }
   
    return (
        <Dialog visible={createDialog} style={{ width: '450px' }} header="Detalhes do usuário" modal className="p-fluid" onHide={hideDialog}>

            <Formik validationSchema={SignupSchema} initialValues={initialValues} onSubmit={(values) => {
                CreateUser(values, hideDialog)
            }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText name="name" id="name" value={values?.name} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.name && touched.name })} />
                                {errors.name && touched.name && <small className="p-invalid">{errors.name}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Nome de usuário</label>
                                <InputText name="username" id="name" value={values?.username} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.username && touched.username })} />
                                {errors.username && touched.username && <small className="p-invalid">{errors.username}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">E-mail *</label>
                                <InputText name="email" id="name" value={values?.email} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.email && touched.email })} />
                                {errors.email && touched.email && <small className="p-invalid">{errors.email}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Senha *</label>
                                <Password name="password" id="name" value={values?.password} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors?.password && touched?.password })} toggleMask />

                                {errors.password && touched?.password && <small className="p-invalid">{errors?.password}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Confirmar senha *</label>
                                <Password name="passwordConfirm" id="name" value={values?.passwordConfirm} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.passwordConfirm && touched.passwordConfirm })} toggleMask />
                                {errors.passwordConfirm && touched.passwordConfirm && <small className="p-invalid">{errors.passwordConfirm}</small>}
                            </div>
                            <div className="p-5"></div>
                            <div className="flex align-items-center justify-content-end">
                                <div className=" w-6 flex align-items-center justify-content-end">
                                    <Button label="Cancel" type="button" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    <Button label="Create" type="submit" icon="pi pi-check" className="p-button-text" />
                                </div>
                            </div>

                        </Form>
                    )
                }}
            </Formik>


        </Dialog>
    )
}

export default ModalCreateUser