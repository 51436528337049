import http from "../../../service/axios/httpRequest";
import { logout } from "../../../service/localStorage/localStorage";


export const getAllUsersRequest = async () => {
     return await http.get("/bff/users-bff/get-all-users").then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}

export const EditUserRequest = async (body) => {
     return await http.put("/bff/users-bff/update-user", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()

                    window.location.reload()
               }
               alert(err.response.data.message)
               throw err;
          });
}

export const SignUpRequest = async (body) => {
     return await http.post("/direct/users", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }

               alert(err.response.data.message)
               throw err;
          });
}